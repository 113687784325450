<template>
  <v-card>
    <LoadingModal :visible="loading" />

    <v-card-title>
      <span class="headline">
        {{ data.id ? "Editar proposta" : "Cadastrar proposta" }}
      </span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <v-form>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Oportunidade*"
                :disabled="enabledInputs"
                v-model="data.oportunity"
                :error-messages="errors.oportunity"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Nome*"
                :disabled="enabledInputs"
                v-model="data.name"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                label="E-mail*"
                :disabled="enabledInputs"
                v-model="data.email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Telefone*"
                :disabled="enabledInputs"
                v-model="data.phone"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Cargo"
                :disabled="enabledInputs"
                v-model="data.post"
                :error-messages="errors.post"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="status"
                v-model="data.status"
                :error-messages="errors.status"
                label="Status"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field type="date" v-model="data.return"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        outlined
        class="no-text-transform"
        color="primary"
        @click="cancel()"
      >
        Voltar
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="no-text-transform" color="primary" @click="saveData()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import ComercialService from "@/services/Comercial/ComercialService";

export default {
  name: "UserForm",

  mounted: async function () {
    const id = this.$route.params.id;
    if (id !== undefined) {
      this.loading = true;
      await this.showData(id);
    }
  },

  components: {},

  data: () => ({
    data: {},
    errors: {},
    listRoles: [],
    loading: false,
    enabledInputs: false,
    ComercialService: new ComercialService(""),
    status: ["Cliente Fechado", "Proposta Enviada", "Agendar Contato"],
  }),

  methods: {
    async saveData() {
      try {
        this.loading = true;
        this.enabledInputs = true;

        const id = this.data.id !== undefined ? "/" + this.data.id : "";

        await this.ComercialService.storeOrUpdate(
          "commercials" + id,
          this.data
        );

        ToastService("Ação realizada com sucesso.", "success");

        await this.$router.push({ path: "/app/comercial" });
      } catch (exception) {
        console.log(exception);
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loading = false;
        this.enabledInputs = false;
      }
    },

    getDate(payload) {
      this.data.data_retorno = payload.date;
    },

    async showData(id) {
      try {
        this.data = await this.ComercialService.show("commercials/" + id);
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$router.push({ path: "/app/comercial" });
    },
  },
};
</script>
